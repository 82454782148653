export default function Home() {

    return (
        <>
            <body>
            <>{ /* start page loading */ }

        
{ /* end page loading */ }</>
{/* start main-wrapper section */}
<div className="main-wrapper">
    
    <div id="google_translate_element"></div>

    

                    <script type="text/javascript" src="../translate.google.com/translate_a/elementa0d8.js?cb=googleTranslateElementInit"></script>


    {/* start header section */}
    <header className="header-style1 menu_area-light">
        <div id="top-bar">
            <div className="container">
                <div className="row">
                    <div className="col-md-9 col-xs-12">
                        <div className="top-bar-info">
                            <ul>
                                <li><i className="fas fa-envelope"></i>support@octainvtradings.com</li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-xs-12 col-md-3 xs-display-none">
                        <ul className="top-social-icon">

                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div className="navbar-default">

            {/* start top search */}
            <div className="top-search bg-theme">
                <div className="container">
                    <form className="search-form" action="#" method="GET" accept-charset="utf-8">
                        <div className="input-group">
                        <span className="input-group-addon cursor-pointer">
                            <button className="search-form_submit fas fa-search font-size18 text-white" type="submit"></button>
                        </span>
                            <input type="text" className="search-form_input form-control" name="s" autoComplete="off" placeholder="Type & hit enter..."/>
                            <span className="input-group-addon close-search"><i className="fas fa-times font-size18 line-height-28 margin-5px-top"></i></span>
                        </div>
                    </form>
                </div>
            </div>
            {/* end top search */}

            <div className="container">
                <div className="row align-items-center">
                    <div className="col-12 col-lg-12">
                        <div className="menu_area alt-font">
                            <nav className="navbar navbar-expand-lg navbar-light no-padding">

                                <div className="navbar-header navbar-header-custom">
                                    {/* start logo */}
                                    <a href="index.html" className="navbar-brand logo2">
                            <img src="img/logo.png" alt="OctaInvest Logo" style={{ height: '40px' }} />
                        </a>
                                    {/* end logo */}
                                </div>

                                <div className="navbar-toggler"></div>

                                {/* menu area */}
                                <ul className="navbar-nav ml-auto" id="nav" style={{display: 'none'}}>
                                    <li><a href="/">Home</a></li>

                                    <li><a href="/pricing">Pricing</a></li>

                                    <li><a href="/about">About Us</a></li>
                                    <li><a href="https://dashboard.octainvtradings.com/signup"><span className="btn-style2 small theme">JOIN NOW</span></a></li>
                                </ul>
                                {/* end menu area */}

                                {/* start attribute navigation */}
                                <div className="attr-nav sm-no-margin sm-margin-70px-right xs-margin-65px-right">
                                    <span className="sm-margin-20px-right xs-margin-5px-right">
                                        <a href="https://dashboard.octainvtradings.com/" className="btn-style2 small theme">
                                            {/* <i className="fa fa-user-circle">Account</i> */}
                                            <span>LOGIN</span>
                                        </a>
                                    </span>
                                    
                                    {/* <li className="search"><a href="javascript:void(0)"><i className="fas fa-search"></i></a></li> */}
                                </div>
                                {/* end attribute navigation */}

                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </header>
    {/* end header section */}



{/* start revolution slideshow*/}
<div id="rev_slider_151_1_wrapper" className="rev_slider_wrapper fullscreen-container" data-alias="blur-effect-slider" data-source="gallery" style={{backgroundColor: '#2d3032', padding: '0px'}}>
            {/* START REVOLUTION SLIDER 5.4.1 fullscreen mode */}
            <div id="rev_slider_151_1" className="rev_slider fullscreenbanner" style={{display: 'none'}} data-version="5.4.1">
                <ul>
                    {/* SLIDE  */}
                    <li data-index="rs-411" data-transition="fadethroughtransparent" data-slotamount="default" data-hideafterloop="0" data-hideslideonmobile="off" data-easein="default" data-easeout="default" data-masterspeed="default" data-rotate="0" data-fstransition="fade"
                        data-fsmasterspeed="1000" data-fsslotamount="7" data-saveperformance="off" data-title="One" data-param1="" data-param2="" data-param3="" data-param4="" data-param5="" data-param6="" data-param7="" data-param8="" data-param9="" data-param10=""
                        data-description="">

                        {/* overlay */}
                        <div className="opacity-extra-medium bg-black z-index-1"></div>

                        {/* MAIN IMAGE */}
                        <img src="./img//slider/slide155.jpg" alt="" data-bgposition="center center" data-bgfit="cover" data-bgrepeat="no-repeat" data-bgparallax="6" className="rev-slidebg" data-no-retina></img>
                        {/* LAYERS */}
                        <div id="rrzt_411" className="rev_row_zone rev_row_zone_top" style={{zIndex: 9}}>

                            {/* LAYER NR. 1 */}
                            <div className="tp-caption  " id="slide-411-layer-14" data-x="['left','left','left','left']" data-hoffset="['100','100','100','100']" data-voffset="['100','100','100','100']" data-width="none" data-height="none" data-whitespace="nowrap" data-type="row" data-columnbreak="2"
                                data-basealign="slide" data-responsive_offset="on" data-responsive="off" data-frames='[{"delay":10,"speed":300,"frame":"0","from":"opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                                data-margintop="[0,0,0,0]" data-marginright="[0,0,0,0]" data-marginbottom="[0,0,0,0]" data-marginleft="[0,0,0,0]" data-textAlign="['inherit','inherit','inherit','inherit']" data-paddingtop="[240,175,200,150]" data-paddingright="[100,50,40,40]"
                                data-paddingbottom="[0,0,0,0]" data-paddingleft="[100,50,40,40]" style={{zIndex: 9, whiteSpace: 'nowrap', fontSize: '20px', lineHeight: '22px', fontWeight: 400, color: 'rgba(255, 255, 255, 1.00)'}}>

                                {/* LAYER NR. 2 */}
                                <div className="tp-caption  " id="slide-411-layer-15" data-x="['left','left','left','left']" data-hoffset="['100','100','100','100']" data-y="['top','top','top','top']" data-voffset="['100','100','100','100']" data-width="none" data-height="none" data-whitespace="nowrap"
                                    data-type="column" data-responsive_offset="on" data-responsive="off" data-frames='[{"delay":"+0","speed":300,"frame":"0","from":"opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                                    data-columnwidth="100%" data-margintop="[0,0,0,0]" data-marginright="[0,0,0,0]" data-marginbottom="[0,0,0,0]" data-marginleft="[0,0,0,0]" data-textAlign="['inherit','inherit','center','center']" data-paddingtop="[0,0,0,0]"
                                    data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style={{zIndex: 10, width: '100%'}}>

                                    {/* LAYER NR. 3 */}
                                    <div className="tp-caption   tp-resizeme  alt-font" id="slide-411-layer-1" data-x="['left','left','center','center']" data-hoffset="['0','0','0','0']" data-y="['top','top','top','top']" data-voffset="['0','0','230','110']" data-fontsize="['110','90','72','70']"
                                        data-lineheight="['100','90','80','70']" data-width="none" data-height="none" data-whitespace="['nowrap','nowrap','nowrap','normal']" data-type="text" data-basealign="slide" data-responsive_offset="on" data-frames='[{"delay":"+290","speed":2000,"frame":"0","from":"y:50px;opacity:0;fb:20px;","to":"o:1;fb:0;","ease":"Power4.easeOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;fb:0;","ease":"Power3.easeInOut"}]'
                                        data-margintop="[0,0,0,0]" data-marginright="[0,0,0,0]" data-marginbottom="[30,20,30,30]" data-marginleft="[0,0,0,0]" data-textAlign="['inherit','inherit','center','center']" data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,40,40]"
                                        data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,40,40]" style={{zIndex: 11, whiteSpace: 'nowrap', fontSize: '110px', lineHeight: '100px', fontWeight: 400, color: 'rgba(255, 255, 255, 1.00)', display: 'inline-block'}}>Invest with
                                        <br/>Trust</div>

                                    {/* LAYER NR. 4 */}
                                    <div className="tp-caption   tp-resizeme" id="slide-411-layer-2" data-x="['left','left','center','center']" data-hoffset="['0','50','0','0']" data-y="['top','top','top','top']" data-voffset="['0','430','460','290']" data-fontsize="['40','30','32','24']" data-lineheight="['50','40','48','30']"
                                        data-width="['640','360','100%','100%']" data-height="none" data-whitespace="normal" data-type="text" data-basealign="slide" data-responsive_offset="on" data-frames='[{"delay":"+490","speed":2000,"frame":"0","from":"y:50px;opacity:0;fb:20px;","to":"o:1;fb:0;","ease":"Power4.easeOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;fb:0;","ease":"Power3.easeInOut"}]'
                                        data-margintop="[0,0,0,0]" data-marginright="[0,0,0,0]" data-marginbottom="[40,30,40,40]" data-marginleft="[0,0,0,0]" data-textAlign="['inherit','inherit','center','center']" data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,0]"
                                        data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style={{zIndex: 12, minWidth: '640px', maxWidth: '640px', whiteSpace: 'normal', fontSize: '40px', lineHeight: '50px', fontWeight: 300, color: 'rgba(255, 255, 255, 1.00)', display: 'block'}}>We pioneered cryptocurrency protocols and companies funds, while also offering robust investments in stocks and stock trading to ensure a well-rounded approach to modern investing</div>

                                    {/* LAYER NR. 5 */}
                                    <a className="tp-caption rev-btn  tp-resizeme butn theme white-hover" href="login.html" id="slide-411-layer-13" data-x="['left','left','center','center']" data-hoffset="['0','50','0','0']" data-y="['top','top','top','top']" data-voffset="['0','540','590','400']"
                                        data-width="none" data-height="none" data-whitespace="['normal','nowrap','nowrap','nowrap']" data-type="button" data-actions='' data-basealign="slide" data-responsive_offset="on" data-frames='[{"delay":"+690","speed":2000,"frame":"0","from":"y:50px;opacity:0;fb:20px;","to":"o:1;fb:0;","ease":"Power4.easeOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;fb:0;","ease":"Power3.easeInOut"},{"frame":"hover","speed":"0","ease":"Linear.easeNone","to":"o:1;rX:0;rY:0;rZ:0;z:0;fb:0;"}]'
                                        data-margintop="[0,0,0,0]" data-marginright="[0,0,0,0]" data-marginbottom="[0,0,0,0]" data-marginleft="[0,0,0,0]" data-textAlign="['inherit','inherit','inherit','inherit']" style={{zIndex: 9, display: 'inline-block', whiteSpace: 'nowrap', outline: 'none', letterSpacing: '1px', cursor: 'pointer'}}><span>INVEST NOW</span></a>
                                </div>

                            </div>

                        </div>

                    </li>
                    {/* SLIDE  */}
                    <li data-index="rs-412" data-transition="fadethroughtransparent" data-slotamount="default" data-hideafterloop="0" data-hideslideonmobile="off" data-easein="default" data-easeout="default" data-masterspeed="default" data-rotate="0" data-saveperformance="off"
                        data-title="Two" data-param1="" data-param2="" data-param3="" data-param4="" data-param5="" data-param6="" data-param7="" data-param8="" data-param9="" data-param10="" data-description="">

                        {/* overlay */}
                        <div className="opacity-extra-medium bg-black z-index-1"></div>

                        {/* MAIN IMAGE */}
                        <img src="./img//slider/slide166.jpg" alt="" data-bgposition="center center" data-bgfit="cover" data-bgrepeat="no-repeat" data-bgparallax="6" className="rev-slidebg" data-no-retina></img>
                        {/* LAYERS */}
                        <div id="rrzt_412" className="rev_row_zone rev_row_zone_top" style={{zIndex: 5}}>

                            {/* LAYER NR. 10 */}
                            <div className="tp-caption  " id="slide-412-layer-14" data-x="['left','left','left','left']" data-hoffset="['100','100','100','100']" data-y="['top','top','top','top']" data-voffset="['100','100','100','100']" data-width="none" data-height="none" data-whitespace="nowrap"
                                data-type="row" data-columnbreak="2" data-basealign="slide" data-responsive_offset="on" data-responsive="off" data-frames='[{"delay":10,"speed":300,"frame":"0","from":"opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                                data-margintop="[0,0,0,0]" data-marginright="[0,0,0,0]" data-marginbottom="[0,0,0,0]" data-marginleft="[0,0,0,0]" data-textAlign="['inherit','inherit','inherit','inherit']" data-paddingtop="[240,175,200,150]" data-paddingright="[100,50,40,40]"
                                data-paddingbottom="[0,0,0,0]" data-paddingleft="[100,50,40,40]" style={{zIndex: 5, whiteSpace: 'nowrap', fontSize: '20px', lineHeight: '22px', fontWeight: 400, color: 'rgba(255, 255, 255, 1.00)'}}>
                                {/* LAYER NR. 11 */}
                                <div className="tp-caption  " id="slide-412-layer-15" data-x="['left','left','left','left']" data-hoffset="['100','100','100','100']" data-y="['top','top','top','top']" data-voffset="['100','100','100','100']" data-width="none" data-height="none" data-whitespace="nowrap"
                                    data-type="column" data-responsive_offset="on" data-responsive="off" data-frames='[{"delay":"+0","speed":300,"frame":"0","from":"opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                                    data-columnwidth="100%" data-margintop="[0,0,0,0]" data-marginright="[0,0,0,0]" data-marginbottom="[0,0,0,0]" data-marginleft="[0,0,0,0]" data-textAlign="['inherit','inherit','center','center']" data-paddingtop="[0,0,0,0]"
                                    data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style={{zIndex: 6, width: '100%'}}>
                                    {/* LAYER NR. 12 */}
                                    <div className="tp-caption   tp-resizeme  alt-font" id="slide-412-layer-1" data-x="['left','left','center','center']" data-hoffset="['0','0','0','0']" data-y="['top','top','top','top']" data-voffset="['0','0','230','110']" data-fontsize="['110','90','72','70']"
                                        data-lineheight="['100','90','80','70']" data-width="['none','none','none','399']" data-height="none" data-whitespace="['nowrap','nowrap','nowrap','normal']" data-type="text" data-basealign="slide" data-responsive_offset="on"
                                        data-frames='[{"delay":"+290","speed":2000,"frame":"0","from":"y:50px;opacity:0;fb:20px;","to":"o:1;fb:0;","ease":"Power4.easeOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;fb:0;","ease":"Power3.easeInOut"}]'
                                        data-margintop="[0,0,0,0]" data-marginright="[0,0,0,0]" data-marginbottom="[30,20,30,30]" data-marginleft="[0,0,0,0]" data-textAlign="['inherit','inherit','center','center']" data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,40,40]"
                                        data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,40,40]" style={{zIndex: 7, whiteSpace: 'nowrap', fontSize: '110px', lineHeight: '100px', fontWeight: 400, color: 'rgba(255, 255, 255, 1.00)', display: 'inline-block'}}>Diversify your
                                        <br/>Assets</div>

                                    {/* LAYER NR. 13 */}
                                    <div className="tp-caption   tp-resizeme" id="slide-412-layer-2" data-x="['left','left','center','center']" data-hoffset="['0','50','0','0']" data-y="['top','top','top','top']" data-voffset="['0','430','460','290']" data-fontsize="['40','30','32','24']" data-lineheight="['50','40','48','30']"
                                        data-width="['640','360','100%','100%']" data-height="none" data-whitespace="normal" data-type="text" data-basealign="slide" data-responsive_offset="on" data-frames='[{"delay":"+490","speed":2000,"frame":"0","from":"y:50px;opacity:0;fb:20px;","to":"o:1;fb:0;","ease":"Power4.easeOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;fb:0;","ease":"Power3.easeInOut"}]'
                                        data-margintop="[0,0,0,0]" data-marginright="[0,0,0,0]" data-marginbottom="[40,30,40,40]" data-marginleft="[0,0,0,0]" data-textAlign="['inherit','inherit','center','center']" data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,0]"
                                        data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style={{zIndex: 8, minWidth: '640px', maxWidth: '640px', whiteSpace: 'normal', fontSize: '40px', lineHeight: '50px', fontWeight: 300, color: 'rgba(255, 255, 255, 1.00)', display: 'block'}}>Access multiple assets from a single investment</div>

                                    {/* LAYER NR. 14 */}
                                    <a className="tp-caption rev-btn  tp-resizeme butn theme white-hover" href="https://dashboard.octainvtradings.com/" target="_blank" id="slide-412-layer-13" data-x="['left','left','center','center']" data-hoffset="['0','50','0','0']" data-y="['top','top','top','top']" data-voffset="['0','540','590','400']"
                                        data-width="none" data-height="none" data-whitespace="['normal','nowrap','nowrap','nowrap']" data-type="button" data-actions='' data-basealign="slide" data-responsive_offset="on" data-frames='[{"delay":"+690","speed":2000,"frame":"0","from":"y:50px;opacity:0;fb:20px;","to":"o:1;fb:0;","ease":"Power4.easeOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;fb:0;","ease":"Power3.easeInOut"},{"frame":"hover","speed":"0","ease":"Linear.easeNone","to":"o:1;rX:0;rY:0;rZ:0;z:0;fb:0;"}]'
                                        data-margintop="[0,0,0,0]" data-marginright="[0,0,0,0]" data-marginbottom="[0,0,0,0]" data-marginleft="[0,0,0,0]" data-textAlign="['inherit','inherit','inherit','inherit']" style={{zIndex: 9, display: 'inline-block', whiteSpace: 'nowrap', outline: 'none', letterSpacing: '1px', cursor: 'pointer'}}><span>INVEST NOW</span></a>
                                </div>
                            </div>
                        </div>

                    </li>
                    {/* SLIDE  */}
                    <li data-index="rs-413" data-transition="fadethroughtransparent" data-slotamount="default" data-hideafterloop="0" data-hideslideonmobile="off" data-easein="default" data-easeout="default" data-masterspeed="default" data-rotate="0" data-saveperformance="off"
                        data-title="Three" data-param1="" data-param2="" data-param3="" data-param4="" data-param5="" data-param6="" data-param7="" data-param8="" data-param9="" data-param10="" data-description="">

                        {/* overlay */}
                        <div className="opacity-extra-medium bg-black z-index-1"></div>

                        {/* MAIN IMAGE */}
                        <img src="./img//slider/slide177.jpg" alt="" data-bgposition="center center" data-bgfit="cover" data-bgrepeat="no-repeat" data-bgparallax="6" className="rev-slidebg" data-no-retina></img>
                        {/* LAYERS */}
                        <div id="rrzt_413" className="rev_row_zone rev_row_zone_top" style={{zIndex: 5}}>

                            {/* LAYER NR. 19 */}
                            <div className="tp-caption  " id="slide-413-layer-14" data-x="['left','left','left','left']" data-hoffset="['100','100','100','100']" data-y="['top','top','top','top']" data-voffset="['100','100','100','100']" data-width="none" data-height="none" data-whitespace="nowrap"
                                data-type="row" data-columnbreak="2" data-basealign="slide" data-responsive_offset="on" data-responsive="off" data-frames='[{"delay":10,"speed":300,"frame":"0","from":"opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                                data-margintop="[0,0,0,0]" data-marginright="[0,0,0,0]" data-marginbottom="[0,0,0,0]" data-marginleft="[0,0,0,0]" data-textAlign="['inherit','inherit','inherit','inherit']" data-paddingtop="[240,175,200,150]" data-paddingright="[100,50,40,40]"
                                data-paddingbottom="[0,0,0,0]" data-paddingleft="[100,50,40,40]" style={{zIndex: 5, whiteSpace: 'nowrap', fontSize: '20px', lineHeight: '22px', fontWeight: 400, color: 'rgba(255, 255, 255, 1.00)'}}>
                                {/* LAYER NR. 20 */}
                                <div className="tp-caption  " id="slide-413-layer-15" data-x="['left','left','left','left']" data-hoffset="['100','100','100','100']" data-y="['top','top','top','top']" data-voffset="['100','100','100','100']" data-width="none" data-height="none" data-whitespace="nowrap"
                                    data-type="column" data-responsive_offset="on" data-responsive="off" data-frames='[{"delay":"+0","speed":300,"frame":"0","from":"opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                                    data-columnwidth="100%" data-margintop="[0,0,0,0]" data-marginright="[0,0,0,0]" data-marginbottom="[0,0,0,0]" data-marginleft="[0,0,0,0]" data-textAlign="['inherit','inherit','center','center']" data-paddingtop="[0,0,0,0]"
                                    data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style={{zIndex: 6, width: '100%'}}>
                                    {/* LAYER NR. 21 */}
                                    <div className="tp-caption   tp-resizeme  alt-font" id="slide-413-layer-1" data-x="['left','left','center','center']" data-hoffset="['0','0','0','0']" data-y="['top','top','top','top']" data-voffset="['0','0','230','110']" data-fontsize="['110','90','72','70']"
                                        data-lineheight="['100','90','80','70']" data-width="none" data-height="none" data-whitespace="['nowrap','nowrap','nowrap','normal']" data-type="text" data-basealign="slide" data-responsive_offset="on" data-frames='[{"delay":"+290","speed":2000,"frame":"0","from":"y:50px;opacity:0;fb:20px;","to":"o:1;fb:0;","ease":"Power4.easeOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;fb:0;","ease":"Power3.easeInOut"}]'
                                        data-margintop="[0,0,0,0]" data-marginright="[0,0,0,0]" data-marginbottom="[30,20,30,30]" data-marginleft="[0,0,0,0]" data-textAlign="['inherit','inherit','center','center']" data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,40,40]"
                                        data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,40,40]" style={{zIndex: 7, whiteSpace: 'nowrap', fontSize: '110px', lineHeight: '100px', fontWeight: 400, color: 'rgba(255, 255, 255, 1.00)', display: 'inline-block'}}>Secure
                                        <br/>the future </div>

                                    {/* LAYER NR. 22 */}
                                    <div className="tp-caption   tp-resizeme" id="slide-413-layer-2" data-x="['left','left','center','center']" data-hoffset="['0','50','0','0']" data-y="['top','top','top','top']" data-voffset="['0','430','460','290']" data-fontsize="['40','30','32','24']" data-lineheight="['50','40','48','30']"
                                        data-width="['640','360','100%','100%']" data-height="none" data-whitespace="normal" data-type="text" data-basealign="slide" data-responsive_offset="on" data-frames='[{"delay":"+490","speed":2000,"frame":"0","from":"y:50px;opacity:0;fb:20px;","to":"o:1;fb:0;","ease":"Power4.easeOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;fb:0;","ease":"Power3.easeInOut"}]'
                                        data-margintop="[0,0,0,0]" data-marginright="[0,0,0,0]" data-marginbottom="[40,30,40,40]" data-marginleft="[0,0,0,0]" data-textAlign="['inherit','inherit','center','center']" data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,0]"
                                        data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style={{zIndex: 8, minWidth: '640px', maxWidth: '640px', whiteSpace: 'normal', fontSize: '40px', lineHeight: '50px', fontWeight: 300, color: 'rgba(255, 255, 255, 1.00)', display: 'block'}}>Crypto protocols and stock trading represent the future of investing, paving the way for innovative financial solutions and new opportunities in both the digital and traditional markets.</div>

                                    {/* LAYER NR. 23 */}
                                    <a className="tp-caption rev-btn  tp-resizeme butn theme white-hover" href="https://dashboard.octainvtradings.com/" id="slide-413-layer-13" data-x="['left','left','center','center']" data-hoffset="['0','50','0','0']" data-y="['top','top','top','top']" data-voffset="['0','540','590','400']"
                                        data-width="none" data-height="none" data-whitespace="['normal','nowrap','nowrap','nowrap']" data-type="button" data-actions='' data-basealign="slide" data-responsive_offset="on" data-frames='[{"delay":"+690","speed":2000,"frame":"0","from":"y:50px;opacity:0;fb:20px;","to":"o:1;fb:0;","ease":"Power4.easeOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;fb:0;","ease":"Power3.easeInOut"},{"frame":"hover","speed":"0","ease":"Linear.easeNone","to":"o:1;rX:0;rY:0;rZ:0;z:0;fb:0;"}]'
                                        data-margintop="[0,0,0,0]" data-marginright="[0,0,0,0]" data-marginbottom="[0,0,0,0]" data-marginleft="[0,0,0,0]" data-textAlign="['inherit','inherit','inherit','inherit']" style={{zIndex: 9, display: 'inline-block', whiteSpace: 'nowrap', outline: 'none', letterSpacing: '1px', cursor: 'pointer'}}><span>INVEST NOW</span></a>
                                </div>
                            </div>
                        <div/>
                        </div>

                    </li>
                </ul>

                <div style={{}} className='tp-static-layers'>

                    {/* LAYER NR. 28 */}
                    <div className="tp-caption   tp-static-layer" id="slider-151-layer-29" data-x="['right','right','right','right']" data-hoffset="['0','0','0','0']" data-y="['middle','middle','bottom','bottom']" data-voffset="['-50','-50','119','119']" data-width="['260','260','200','70']"
                        data-height="none" data-whitespace="nowrap" data-visibility="['on','on','on','off']" data-type="text" data-basealign="slide" data-responsive_offset="off" data-responsive="off" data-startslide="0" data-endslide="2" data-frames='[{"delay":10,"speed":300,"frame":"0","from":"opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                        data-textAlign="['inherit','inherit','inherit','inherit']" data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style={{zIndex: 17, minWidth: '260px', maxWidth: '260px', whiteSpace: 'nowrap', fontSize: '15px', lineHeight: '15px', fontWeight: 500, color: 'rgba(255, 255, 255, 1.00)'}}>1
                        <div className="blurslider-line" style={{display: 'inline-block', width: '40px', height: '1px', background: '#fff', margin: '10px 20px 4px 15px'}}></div>Trust </div>

                    {/* LAYER NR. 29 */}
                    <div className="tp-caption tp-shape tp-shapewrapper  tp-static-layer" id="slider-151-layer-22" data-x="['right','right','right','right']" data-hoffset="['0','0','0','0']" data-y="['middle','middle','bottom','bottom']" data-voffset="['-50','-50','102','102']"
                        data-width="['300','300','240','30%']" data-height="50" data-whitespace="nowrap" data-visibility="['on','on','on','off']" data-type="shape" data-basealign="slide" data-responsive_offset="off" data-responsive="off" data-startslide="0"
                        data-endslide="2" data-frames='[{"delay":"bytrigger","speed":800,"frame":"0","from":"x:[-100%];","mask":"x:0px;y:0px;s:inherit;e:inherit;","to":"o:1;","ease":"Power4.easeOut"},{"delay":"bytrigger","speed":500,"frame":"999","to":"x:[-100%];opacity:1;","mask":"x:0;y:0;s:inherit;e:inherit;","ease":"Power4.easeOut"}]'
                        data-textAlign="['inherit','inherit','inherit','inherit']" data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" data-blendmode="overlay" data-lasttriggerstate="reset"
                        style={{zIndex: 18, backgroundColor: 'rgba(0, 0, 0, 0.25)', cursor: 'pointer'}}> </div>

                    {/* LAYER NR. 30 */}
                    <div className="tp-caption tp-shape tp-shapewrapper  tp-static-layer" id="slider-151-layer-21" data-x="['right','right','right','right']" data-hoffset="['0','0','0','0']" data-y="['middle','middle','bottom','bottom']" data-voffset="['-50','-50','102','102']"
                        data-width="['300','300','240','30%']" data-height="50" data-whitespace="nowrap" data-visibility="['on','on','on','off']" data-type="shape" data-actions='[{"event":"mouseenter","action":"startlayer","layer":"slider-151-layer-22","delay":""},{"event":"mouseleave","action":"stoplayer","layer":"slider-151-layer-22","delay":""},{"event":"click","action":"jumptoslide","slide":"rs-411","delay":""}]'
                        data-basealign="slide" data-responsive_offset="off" data-responsive="off" data-startslide="0" data-endslide="2" data-frames='[{"delay":10,"speed":1000,"frame":"0","from":"x:[-100%];","mask":"x:0px;y:0px;s:inherit;e:inherit;","to":"o:1;","ease":"Power4.easeOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"},{"frame":"hover","speed":"0","ease":"Power4.easeOut","to":"o:1;rX:0;rY:0;rZ:0;z:0;","style":"c:rgba(0, 0, 0, 1.00);bs:solid;bw:0 0 0 0;"}]'
                        data-textAlign="['inherit','inherit','inherit','inherit']" data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" data-blendmode="overlay" style={{zIndex: 19, backgroundColor: 'rgba(255, 255, 255, 0.05)', borderColor: 'rgba(0, 0, 0, 0)', cursor: 'pointer'}}>
                    </div>

                    {/* LAYER NR. 31 */}
                    <div className="tp-caption   tp-static-layer" id="slider-151-layer-28" data-x="['right','right','right','right']" data-hoffset="['0','0','0','0']" data-y="['middle','middle','bottom','bottom']" data-voffset="['0','0','68','68']" data-width="['260','260','200','70']"
                        data-height="none" data-whitespace="nowrap" data-visibility="['on','on','on','off']" data-type="text" data-basealign="slide" data-responsive_offset="off" data-responsive="off" data-startslide="0" data-endslide="2" data-frames='[{"delay":10,"speed":300,"frame":"0","from":"opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                        data-textAlign="['inherit','inherit','inherit','inherit']" data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style={{zIndex: 20, minWidth: '260px', maxWidth: '260px', whiteSpace: 'nowrap', fontSize: '15px', lineHeight: '15px', fontWeight: 500, color: 'rgba(255, 255, 255, 1.00)'}}>2
                        <div className="blurslider-line" style={{display: 'inline-block', width: '40px', height: '1px', background: '#fff', margin: '10px 20px 4px 15px'}}></div>Diversify </div>

                    {/* LAYER NR. 32 */}
                    <div className="tp-caption tp-shape tp-shapewrapper  tp-static-layer" id="slider-151-layer-24" data-x="['right','right','right','right']" data-hoffset="['0','0','0','0']" data-y="['middle','middle','bottom','bottom']" data-voffset="['1','1','51','51']" data-width="['300','300','240','30%']"
                        data-height="50" data-whitespace="nowrap" data-visibility="['on','on','on','off']" data-type="shape" data-basealign="slide" data-responsive_offset="off" data-responsive="off" data-startslide="0" data-endslide="2" data-frames='[{"delay":"bytrigger","speed":800,"frame":"0","from":"x:[-100%];","mask":"x:0px;y:0px;s:inherit;e:inherit;","to":"o:1;","ease":"Power4.easeOut"},{"delay":"bytrigger","speed":500,"frame":"999","to":"x:[-100%];opacity:1;","mask":"x:0;y:0;s:inherit;e:inherit;","ease":"Power4.easeOut"}]'
                        data-textAlign="['inherit','inherit','inherit','inherit']" data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" data-blendmode="overlay" data-lasttriggerstate="reset"
                        style={{zIndex: 21, backgroundColor: 'rgba(0, 0, 0, 0.25)', cursor: 'pointer'}}> </div>

                    {/* LAYER NR. 33 */}
                    <div className="tp-caption tp-shape tp-shapewrapper  tp-static-layer" id="slider-151-layer-23" data-x="['right','right','right','right']" data-hoffset="['0','0','0','0']" data-y="['middle','middle','bottom','bottom']" data-voffset="['1','1','51','51']" data-width="['300','300','240','30%']"
                        data-height="50" data-whitespace="nowrap" data-visibility="['on','on','on','off']" data-type="shape" data-actions='[{"event":"mouseenter","action":"startlayer","layer":"slider-151-layer-24","delay":""},{"event":"mouseleave","action":"stoplayer","layer":"slider-151-layer-24","delay":""},{"event":"click","action":"jumptoslide","slide":"rs-412","delay":""}]'
                        data-basealign="slide" data-responsive_offset="off" data-responsive="off" data-startslide="0" data-endslide="2" data-frames='[{"delay":10,"speed":1000,"frame":"0","from":"x:[-100%];","mask":"x:0px;y:0px;s:inherit;e:inherit;","to":"o:1;","ease":"Power4.easeOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"},{"frame":"hover","speed":"0","ease":"Power4.easeOut","to":"o:1;rX:0;rY:0;rZ:0;z:0;","style":"c:rgba(0, 0, 0, 1.00);bs:solid;bw:0 0 0 0;"}]'
                        data-textAlign="['inherit','inherit','inherit','inherit']" data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" data-blendmode="overlay" style={{zIndex: 22, backgroundColor: 'rgba(255, 255, 255, 0.05)', borderColor: 'rgba(0, 0, 0, 0)', cursor: 'pointer'}}>
                    </div>

                    {/* LAYER NR. 34 */}
                    <div className="tp-caption   tp-static-layer" id="slider-151-layer-27" data-x="['right','right','right','right']" data-hoffset="['0','0','0','0']" data-y="['middle','middle','bottom','bottom']" data-voffset="['50','50','17','17']" data-width="['260','260','200','70']"
                        data-height="none" data-whitespace="nowrap" data-visibility="['on','on','on','off']" data-type="text" data-basealign="slide" data-responsive_offset="off" data-responsive="off" data-startslide="0" data-endslide="2" data-frames='[{"delay":10,"speed":300,"frame":"0","from":"opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                        data-textAlign="['inherit','inherit','inherit','inherit']" data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style={{zIndex: 23, minWidth: '260px', maxWidth: '260px', whiteSpace: 'nowrap', fontSize: '15px', lineHeight: '15px', fontWeight: 500, color: 'rgba(255, 255, 255, 1.00)'}}>3
                        <div className="blurslider-line" style={{display: 'inline-block', width: '40px', height: '1px', background: '#fff', margin: '10px 20px 4px 15px'}}></div>Future </div>

                    {/* LAYER NR. 35 */}
                    <div className="tp-caption tp-shape tp-shapewrapper  tp-static-layer" id="slider-151-layer-26" data-x="['right','right','right','right']" data-hoffset="['0','0','0','0']" data-y="['middle','middle','bottom','bottom']" data-voffset="['52','52','0','0']" data-width="['300','300','240','30%']"
                        data-height="50" data-whitespace="nowrap" data-visibility="['on','on','on','off']" data-type="shape" data-basealign="slide" data-responsive_offset="off" data-responsive="off" data-startslide="0" data-endslide="2" data-frames='[{"delay":"bytrigger","speed":800,"frame":"0","from":"x:[-100%];","mask":"x:0px;y:0px;s:inherit;e:inherit;","to":"o:1;","ease":"Power4.easeOut"},{"delay":"bytrigger","speed":500,"frame":"999","to":"x:[-100%];opacity:1;","mask":"x:0;y:0;s:inherit;e:inherit;","ease":"Power4.easeOut"}]'
                        data-textAlign="['inherit','inherit','inherit','inherit']" data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" data-blendmode="overlay" data-lasttriggerstate="reset"
                        style={{zIndex: 24, backgroundColor: 'rgba(0, 0, 0, 0.25)', cursor: 'pointer'}}> </div>

                    {/* LAYER NR. 36 */}
                    <div className="tp-caption tp-shape tp-shapewrapper  tp-static-layer" id="slider-151-layer-25" data-x="['right','right','right','right']" data-hoffset="['0','0','0','0']" data-y="['middle','middle','bottom','bottom']" data-voffset="['52','52','0','0']" data-width="['300','300','240','30%']"
                        data-height="50" data-whitespace="nowrap" data-visibility="['on','on','on','off']" data-type="shape" data-actions='[{"event":"mouseenter","action":"startlayer","layer":"slider-151-layer-26","delay":""},{"event":"mouseleave","action":"stoplayer","layer":"slider-151-layer-26","delay":""},{"event":"click","action":"jumptoslide","slide":"rs-413","delay":""}]'
                        data-basealign="slide" data-responsive_offset="off" data-responsive="off" data-startslide="0" data-endslide="2" data-frames='[{"delay":10,"speed":1000,"frame":"0","from":"x:[-100%];","mask":"x:0px;y:0px;s:inherit;e:inherit;","to":"o:1;","ease":"Power4.easeOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"},{"frame":"hover","speed":"0","ease":"Power4.easeOut","to":"o:1;rX:0;rY:0;rZ:0;z:0;","style":"c:rgba(0, 0, 0, 1.00);bs:solid;bw:0 0 0 0;"}]'
                        data-textAlign="['inherit','inherit','inherit','inherit']" data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" data-blendmode="overlay" style={{zIndex: 25, backgroundColor: 'rgba(255, 255, 255, 0.05)', borderColor: 'rgba(0, 0, 0, 0)', cursor: 'pointer'}}>
                    </div>
                </div>
                <div className="tp-bannertimer" style={{height: '10px', backgroundColor: 'rgba(255, 255, 255, 0.25)'}}></div>
            </div>
        </div>
        {/* end revolution slideshow*/}

    {/* start quote */}
    <div className="bg-theme padding-40px-tb">
            <div className="container">
                {/*Row Start*/}
                <div className="row">
                    <div className="col-md-12 text-center">
                        <h6 className="text-white margin-10px-bottom line-height-40 font-weight-600">New to investing? Let our advisors guide you on how to invest!</h6>
                        <a href="https://dashboard.octainvtradings.com/" className="butn white" target="_blank"><span>Get Started</span></a>
                    </div>
                </div>
                {/*Row End*/}
            </div>
    </div>
    {/* end quote */}



{/* start service section */}
<section>
            <div className="container">
                <div className="row">
                    <div className="col-lg-5 col-md-6">

                        <div className="display-table height-100">
                            <div className="display-table-cell vertical-align-middle">
                                <h2 className="font-size36 md-font-size32 sm-font-size28 xs-font-size24 text-uppercase font-weight-800 no-margin xs-margin-20px-bottom">A Chance To Own<br/><span className="text-theme-color">Blockchain Assets</span></h2>
                            </div>
                        </div>

                    </div>

                    <div className="col-lg-7 col-md-6">
                        <div className="height-100 border-left border-color-light-black padding-30px-left xs-no-padding-left">
                            <p className="no-margin width-95">Blockchain assets are the wealth of today and the future. Octa Investment is giving investors the opportunity to not only profit from blockchain assets, but also, to own them. Owning a blockchain asset today means owning the future.</p>
                        </div>
                    </div>

                    <div className="col-12 margin-50px-tb sm-margin-40px-tb xs-margin-30px-top xs-margin-40px-bottom">
                        <div className="bg-black separator-line-horrizontal-full opacity1"></div>
                    </div>

                </div>                

            </div>
        {/* end service section */}

        {/* start about section */}
            <div className="container">

                <div className="row">

                    <div className="col-lg-5 col-md-12 sm-margin-50px-bottom xs-margin-30px-bottom">
                        <img src="assets/miner/Finance-Behavior-by-Board-Room.jpg" alt="" className="border-radius-5 box-shadow-primary"></img>

                    </div>

                    <div className="col-lg-7 col-md-12">
                        <div className="padding-70px-left md-padding-50px-left sm-no-padding">
                            <div className="display-table-cell vertical-align-middle width-100">
                                <h4 className="font-weight-600">We are committed to building sustainable wealth for investors.</h4>
                                <p>That is why our crypto hedge funds invest in Blockchain technology and early token sales. Our inclusive objective is to reinvent investment strategies for token sales. An investment with Octa Investment means:</p>

                                <div className="width-65 xs-width-85">
                                    <ul className="list-style-14 margin-35px-bottom xs-margin-25px-bottom">
                                        <li>Investors own the protocol</li>
                                        <li>Investors profit from token growth</li>
                                        <li>Investors earn from the Blockchain</li>
                                        <li>ROIs are backed by smart contract.</li>
                                    </ul>
                                </div>

                                <div className="row">
                                    <div className="col-lg-4 col-md-4 col-sm-4 mobile-margin-15px-bottom">
                                        <div className="counter-box-style2 bg-white text-center">
                                            <h4 className="countup display-block font-size24 sm-font-size22 no-margin font-weight-600">74</h4>
                                            <div className="separator-line-horrizontal-medium-light3 bg-theme margin-10px-tb sm-margin-5px-tb center-col"></div>
                                            <p className="text-uppercase font-weight-700 no-margin text-theme-color letter-spacing-1">Standard Investments</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-sm-4 mobile-margin-15px-bottom">
                                        <div className="counter-box-style2 bg-white text-center">
                                            <h4 className="countup display-block font-size24 sm-font-size22 no-margin font-weight-600">6</h4>
                                            <div className="separator-line-horrizontal-medium-light3 bg-theme margin-10px-tb sm-margin-5px-tb center-col"></div>
                                            <p className="text-uppercase font-weight-700 no-margin text-theme-color letter-spacing-1">Diversity Investments</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-sm-4">
                                        <div className="counter-box-style2 bg-white text-center">
                                            <h4 className="countup display-block font-size24 sm-font-size22 no-margin font-weight-600">35</h4>
                                            <div className="separator-line-horrizontal-medium-light3 bg-theme margin-10px-tb sm-margin-5px-tb center-col"></div>
                                            <p className="text-uppercase font-weight-700 no-margin text-theme-color letter-spacing-1">Lead Investments</p>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* end about section */}

        {/* Start banner-2 Section */}
<section className="bg-img cover-background " data-overlay-dark="0" data-background="./img//bg/portfolio-1.png">
    <div className="container height-100">
        <div className="display-table height-100 width-100">
            <div className="display-table-cell vertical-align-middle caption">
                <div className="overflow-hidden width-80 sm-width-85 xs-width-95">
                    <h1 className="text-theme-color font-size50 md-font-size42 sm-font-size38 font-weight-800">Own the Blockchain</h1>
                    <div className="text-theme-color line-height-normal font-weight-400 font-size36 md-font-size32 sm-font-size28 margin-20px-bottom no-letter-spacing width-60 xs-width-70">Don't just invest. Own the Asset.</div>
                    <p className="text-theme-color width-60 xs-width-100 font-size16 xs-display-none line-height-30 xs-font-size14 xs-line-height-26">Our investment strategy allows investors to not only profit through investments into asstes, but to also own the assets, such that an increase in value results in cashflow.</p>
                    <div className="margin-30px-top sm-margin-25px-top"><a href="https://dashboard.octainvtradings.com/signup" className="butn theme"><span>Get Started</span></a></div>
                </div>
            </div>

        </div>
    </div>
</section>
{/* end banner-2 Section */}

        {/* start about ceo section */}
        <section className="parallax no-padding" data-overlay-dark="0" data-background="./img//bg/happy-client.png">
            <div className="container-fluid no-padding">
                <div className="row">

                    <div className="col-lg-6 col-md-12 offset-lg-6">
                        <div className="about-text about-overlay">

                            <div className="width-85 md-width-100 sm-center-col">
                                <div className="section-heading left white">
                                    <h4>We manage your trades and investments</h4>
                                </div>
                                <div className="inner-content">
                                    <h2><span>“We have brought down risks to nearly 0%." </span> for all our active clients.</h2>
                                    <p className="xs-margin-10px-bottom">Our investment strategy is such that we manage the trades and investments on behalf of our investors, so that they do not have to worry about the risks associated with investing in the financial markets.</p>
                                    <p>Simply put, your investments are safer with us.</p>
                                    <div className="bottom">
                                        <p className="thm-clr">Smart investors choose us.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* end about ceo section */}

        {/* start features section */}
        <section className="lg bg-transparent bg-img cover-background" data-overlay-dark="0" data-background="./img//bg/shape-01.png">
            <div className="container">
                <div className="section-heading title-style8">
                    <h3>Why Choose Octa Investment?</h3>
                    <p className="width-55 sm-width-75 xs-width-95 center-col">There are countless reasons why we stand out amongst other investment firms, and our direct competition. Here are some of those reasons:</p>
                </div>
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12 margin-30px-bottom sm-margin-20px-bottom">
                        <div className="feature-box-05 padding-40px-tb md-padding-30px-tb padding-20px-lr bg-white h-100">
                            <div className="row">
                                <div className="col-2">
                                    <div className="features-icon">
                                        <i className="icon-profile-male"></i>
                                    </div>
                                </div>
                                <div className="col-10">
                                    <h4 className="font-weight-600 font-size20 md-font-size18 sm-font-size16 margin-10px-bottom sm-margin-8px-bottom">Best Minds</h4>
                                    <p className="no-margin-bottom">Our team of analysts and traders have the deepest and broadest experiences in the financial markets, and especially in the blockchain ecosystem.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-6 col-sm-12 margin-30px-bottom sm-margin-20px-bottom">
                        <div className="feature-box-05 padding-40px-tb md-padding-30px-tb padding-20px-lr bg-white h-100">
                            <div className="row">
                                <div className="col-2">
                                    <div className="features-icon">
                                        <i className="icon-adjustments"></i>
                                    </div>
                                </div>
                                <div className="col-10">
                                    <h4 className="font-weight-600 font-size20 md-font-size18 sm-font-size16 margin-10px-bottom sm-margin-8px-bottom">Unique Asset Classes</h4>
                                    <p className="no-margin-bottom">Octa Investment looks for tokens that incentivize the core actions of a protocol and/or excel in developing novel mechanisms for smart contracts.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-6 col-sm-12 xs-margin-20px-bottom">
                        <div className="feature-box-05 padding-40px-tb md-padding-30px-tb padding-20px-lr bg-white h-100">
                            <div className="row">
                                <div className="col-2">
                                    <div className="features-icon">
                                        <i className="icon-recycle"></i>
                                    </div>
                                </div>
                                <div className="col-10">
                                    <h4 className="font-weight-600 font-size20 md-font-size18 sm-font-size16 margin-10px-bottom sm-margin-8px-bottom">Advanced Technology</h4>
                                    <p className="no-margin-bottom">We have combined a total of 35 technologies for our trading operations and security systems, to ensure that investors funds are safe.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="feature-box-05 padding-40px-tb md-padding-30px-tb padding-20px-lr bg-white h-100">
                            <div className="row">
                                <div className="col-2">
                                    <div className="features-icon">
                                        <i className="icon-grid"></i>
                                    </div>
                                </div>
                                <div className="col-10">
                                    <h4 className="font-weight-600 font-size20 md-font-size18 sm-font-size16 margin-10px-bottom sm-margin-8px-bottom">Venture Backed</h4>
                                    <p className="no-margin-bottom">Over $400 million in assets from several traditional VC firms from Silicon Valley have been injected into the Octa Investment project.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* end features section */}

          {/* start counter section */}
      <section
        className="parallax"
        data-overlay-dark="6"
        data-background="./img//bg/counter-bg.jpg"
      >
        <div className="container">
          <div className="row">
            <div className="col-6 col-lg-3 col-md-6 sm-margin-30px-bottom">
              <div className="counter-box">
                <span
                  className="icon margin-5px-bottom display-block text-white font-size36 xs-font-size30 xs-text-center"
                  ><i className="icon-profile-male"></i
                ></span>
                <h4 className="countup text-white display-block xs-text-center">
                  36
                </h4>
                <div
                  className="separator-line-horrizontal-medium-light3 bg-white margin-15px-tb xs-margin-10px-tb opacity5 center-col"
                ></div>
                <p
                  className="font-size24 sm-font-size20 xs-font-size18 font-weight-600 text-white no-margin text-center"
                >
                  Industry Partners
                </p>
              </div>
            </div>
            <div className="col-6 col-lg-3 col-md-6 sm-margin-30px-bottom">
              <div className="counter-box">
                <span
                  className="icon margin-5px-bottom display-block text-white font-size36 xs-font-size30 xs-text-center"
                  ><i className="icon-happy"></i
                ></span>
                <h4 className="countup text-white display-block xs-text-center">
                  138,596
                </h4>
                <div
                  className="separator-line-horrizontal-medium-light3 bg-white margin-15px-tb xs-margin-10px-tb opacity5 center-col"
                ></div>
                <p
                  className="font-size24 sm-font-size20 xs-font-size18 font-weight-600 text-white no-margin text-center"
                >
                  Satisfied Investors
                </p>
              </div>
            </div>
            <div className="col-6 col-lg-3 col-md-6">
              <div className="counter-box">
                <span
                  className="icon margin-5px-bottom display-block text-white font-size36 xs-font-size30 xs-text-center"
                  ><i className="icon-trophy"></i
                ></span>
                <h4 className="countup text-white display-block xs-text-center">
                  27
                </h4>
                <div
                  className="separator-line-horrizontal-medium-light3 bg-white margin-15px-tb xs-margin-10px-tb opacity5 center-col"
                ></div>
                <p
                  className="font-size24 sm-font-size20 xs-font-size18 font-weight-600 text-white no-margin text-center"
                >
                  Industry Awards
                </p>
              </div>
            </div>
            <div className="col-6 col-lg-3 col-md-6">
              <div className="counter-box">
                <span
                  className="icon margin-5px-bottom display-block text-white font-size36 xs-font-size30 xs-text-center"
                  ><i className="icon-genius"></i
                ></span>
                <h4 className="countup text-white display-block xs-text-center">
                  1,000,000
                </h4>
                <div
                  className="separator-line-horrizontal-medium-light3 bg-white margin-15px-tb xs-margin-10px-tb opacity5 center-col"
                ></div>
                <p
                  className="font-size24 sm-font-size20 xs-font-size18 font-weight-600 text-white no-margin text-center"
                >
                  Successful Trades
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* end counter section */}

      {/* start testmonials section */}
      <section>
            <div className="container">

                <div className="section-heading title-style5">
                    <span>Testimonial</span>
                    <h3 className="text-uppercase font-weight-600">What Investors Are Saying</h3>
                    <div className="square">
                        <span className="separator-left bg-theme"></span>
                        <span className="separator-right bg-theme"></span>
                    </div>
                </div>

                <div className="owl-carousel owl-theme testmonial-style5">
                    <div className="row testmonials-style5 sm-text-center">
                        <div className="col-md-12 col-lg-4 sm-margin-15px-bottom">
                            <img alt="image" src="./img//testmonials/t-4.jpg"></img>
                        </div>
                        <div className="col-md-12 col-lg-8">
                            <div className="data">
                                <p>Octa Investment is an awesome investment platform! It's easy to use, plus I enjoy the technolgy and quick payments. So happy I found this!</p>
                                <h5>stepharuse</h5>
                                <i className="fas fa-quote-right font-size32 md-font-size30 sm-font-size28 xs-font-size26 opacity1"></i>
                            </div>
                        </div>
                    </div>
                    <div className="row testmonials-style5 sm-text-center">
                        <div className="col-md-12 col-lg-4 sm-margin-15px-bottom">
                            <img alt="image" src="./img//testmonials/t-5.jpg"></img>
                        </div>
                        <div className="col-md-12 col-lg-8">
                            <div className="data">
                                <p>Octa Investment is the only investment company I know that is independent of the big banks, yet with the same protection assurance. That's perfect to me!</p>
                                <h5>d-keithly</h5>
                                <i className="fas fa-quote-right font-size32 md-font-size30 sm-font-size28 xs-font-size26 opacity1"></i>
                            </div>
                        </div>
                    </div>
                    <div className="row testmonials-style5 sm-text-center">
                        <div className="col-md-12 col-lg-4 sm-margin-15px-bottom">
                            <img alt="image" src="./img//testmonials/t-6.jpg"></img>
                        </div>
                        <div className="col-md-12 col-lg-8">
                            <div className="data">
                                <p>I love the fact that I have the option of making my own fortune, as well as access to professionals. I suggest that everyone looking to build wealth should be here.</p>
                                <h5>alivincor35</h5>
                                <i className="fas fa-quote-right font-size32 md-font-size30 sm-font-size28 xs-font-size26 opacity1"></i>
                            </div>
                        </div>
                    </div>
                    {/* // <div className="row testmonials-style5 sm-text-center">
                    //     <div className="col-md-12 col-lg-4 sm-margin-15px-bottom">
                    //         <img alt="image" src="./img//testmonials/t-8.jpg"></img>
                    //     </div>
                    //     <div className="col-md-12 col-lg-8">
                    //         <div className="data">
                    //             <p>Everything is straightforward, and dealing with everyone at Octa Investment gives me confidence that I made a perfect choice. Compared with my previous investment manager, it's night-and-day.</p>
                    //             <h5>pro-investor777</h5>
                    //             <i className="fas fa-quote-right font-size32 md-font-size30 sm-font-size28 xs-font-size26 opacity1"></i>
                    //         </div>
                    //     </div>
                    // </div> */}
                    <div className="row testmonials-style5 sm-text-center">
                        <div className="col-md-12 col-lg-4 sm-margin-15px-bottom">
                            <img alt="image" src="./img//testmonials/t-8.jpg"></img>
                        </div>
                        <div className="col-md-12 col-lg-8">
                            <div className="data">
                                <p>Octa Investment overall appears to be a well run organization and offer the latest technologies to make investing easy!</p>
                                <h5>feitsing_r</h5>
                                <i className="fas fa-quote-right font-size32 md-font-size30 sm-font-size28 xs-font-size26 opacity1"></i>
                            </div>
                        </div>
                    </div>
                    <div className="row testmonials-style5 sm-text-center">
                        <div className="col-md-12 col-lg-4 sm-margin-15px-bottom">
                            <img alt="image" src="./img//testmonials/t-9.jpg"></img>
                        </div>
                        <div className="col-md-12 col-lg-8">
                            <div className="data">
                                <p>The professionalism and transparency in Octa Investment is worth acknowledging. It is companies like this we want to always trust with our wealth journey. I also love the prompt service from your customer care unit..</p>
                                <h5>ahmadini-omar</h5>
                                <i className="fas fa-quote-right font-size32 md-font-size30 sm-font-size28 xs-font-size26 opacity1"></i>
                            </div>
                        </div>
                    </div>
                    <div className="row testmonials-style5 sm-text-center">
                        <div className="col-md-12 col-lg-4 sm-margin-15px-bottom">
                            <img alt="image" src="./img//testmonials/t-10.jpg"></img>
                        </div>
                        <div className="col-md-12 col-lg-8">
                            <div className="data">
                                <p>My first ever investment experience is with Octa Investment, and I really didn't know that investing could be this interesting and exciting. Thank you for helping my dream take shape.</p>
                                <h5>queen-priya</h5>
                                <i className="fas fa-quote-right font-size32 md-font-size30 sm-font-size28 xs-font-size26 opacity1"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* end testmonials section */}

{/* start highlights section */}
<section className="bg-img background-position-left-top background-no-repeat position-relative no-padding" data-overlay-dark="0" data-background="./img//content/content-bg.png">

    {/* start about us section */}
    <section className="position-relative bg-transparent no-padding">
        <div className="container">

        <div className="row align-items-center">
            <div className="col-lg-6 service-design">

                <span>Customer Culture</span>
                <h3>Our customer culture resonates with our success!</h3>
                <p>As a company, we always put the client first, because we know we built this company for them. Our internal corporate culture enables us serve products that are created for humans.</p>
                <a href="https://dashboard.octainvtradings.com/signup" className="butn theme"><span>Start Investing</span></a>

            </div>

            <div className="col-lg-6 col-xl-5 offset-xl-1 sm-display">

                <div className="h-100 min-height-60vh vw-lg-50 bg-img cover-background rounded-left" data-background="./img//content/company-culture.jpg"></div>


                <div className="bg-theme about-project alt-font">
                    <div className="font-size14 text-uppercase font-weight-400 letter-spacing-2 margin-10px-bottom">Investments</div>
                    <div className="countup font-size42">72</div>
                </div>


            </div>

        </div>

    </div>

</section>
{/* end about us section */}

{/* start extra section */}
<section className="bg-transparent no-padding-top lg">
            <div className="container">

                <div className="title-style11">
                    <span>Onboarding Steps</span>
                    <h2>Follow these three simple steps to join us!</h2>
                </div>

                <div className="row justify-content-center">
                    <div className="col-xl-10">
                        {/*Vertical Tab*/}
                        <div className="verticaltab tab-style8">
                            <ul className="resp-tabs-list hor_1">
                                <li id="tab1">

                                    <div className="media align-items-center align-items-lg-start">
                                        <div className="width-50px xs-width-35px">
                                            <i className="ti-world font-size36"></i>
                                        </div>
                                        <div className="media-body">
                                            <h4 className="no-margin-bottom">Log on to our website</h4>
                                            <div className="d-none d-lg-block margin-5px-top">
                                                <p className="no-margin-bottom">On our website, click the JOIN NOW button</p>
                                            </div>
                                        </div>
                                    </div>

                                </li>
                                <li id="tab2">
                                    <div className="media align-items-center align-items-lg-start">
                                        <div className="width-50px xs-width-35px">
                                            <i className="ti-user font-size36"></i>
                                        </div>
                                        <div className="media-body">
                                            <h4 className="no-margin-bottom">Create a free account</h4>
                                            <div className="d-none d-lg-block margin-5px-top">
                                                <p className="no-margin-bottom">Provide your details to create a free account.</p>
                                            </div>
                                        </div>
                                    </div>

                                </li>
                                <li id="tab3">
                                    <div className="media align-items-center align-items-lg-start">
                                        <div className="width-50px xs-width-35px">
                                            <i className="ti-credit-card font-size36"></i>
                                        </div>
                                        <div className="media-body">
                                            <h4 className="no-margin-bottom">Select an investment</h4>
                                            <div className="d-none d-lg-block margin-5px-top">
                                                <p className="no-margin-bottom">Choose a plan and deposit to start earning.</p>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                            <div className="resp-tabs-container hor_1">
                                <div id="first">
                                    <img src="./img//content/signup-plan.jpg" alt="" className="rounded" />
                                </div>
                                <div id="second">
                                    <img src="./img//content/select-plan.jpg" alt="" className="rounded" />
                                </div>
                                <div id="third">
                                    <img src="./img//content/make-deposit.jpg" alt="" className="rounded" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </section>
        {/* end extra section */}

{/* start counter section */}
<section className="bg-transparent no-padding-top lg">
    <div className="container">
        <div className="row">
            <div className="col-md-4 xs-margin-30px-bottom">
                <div className="text-center">
                    <h4 className="countup xs-text-center font-size50 md-font-size42 sm-font-size36 no-margin-bottom font-weight-300">138,596</h4>
                    <div className="separator-line-horrizontal-medium-light3 bg-extra-dark-gray margin-15px-top margin-25px-bottom opacity1 center-col"></div>
                    <p className="no-margin text-center lead">Satisfied Investors</p>
                </div>
            </div>
            <div className="col-md-4 xs-margin-30px-bottom">
                <div className="text-center">
                    <h4 className="countup xs-text-center font-size50 md-font-size42 sm-font-size36 no-margin-bottom font-weight-300">72</h4>
                    <div className="separator-line-horrizontal-medium-light3 bg-extra-dark-gray margin-15px-top margin-25px-bottom opacity1 center-col"></div>
                    <p className="no-margin text-center lead">Unique Investments</p>
                </div>
            </div>
            <div className="col-md-4">
                <div className="text-center">
                    <h4 className="countup xs-text-center font-size50 md-font-size42 sm-font-size36 no-margin-bottom font-weight-300">1,000,000</h4>
                    <div className="separator-line-horrizontal-medium-light3 bg-extra-dark-gray margin-15px-top margin-25px-bottom opacity1 center-col"></div>
                    <p className="no-margin text-center lead">Successful Trades</p>
                </div>
            </div>
        </div>
    </div>
</section>
{/* end counter section */}

{/* start begin investing section */}
<section className="position-relative bg-transparent no-padding">
    <div className="container">
        <div className="row align-items-center">
            <div className="col-lg-6 sm-margin-70px-bottom xs-margin-50px-bottom">
                <div className="pattern-img position-relative z-index-9">
                    <img src="./img//content/signup.jpg" alt="" className="rounded" />
                </div>
            </div>
            <div className="col-md-10 col-lg-6">

                <div className="service-design padding-45px-left sm-no-padding-left">
                    <span>What Next?</span>
                    <h3>Begin your wealth journey with us now!</h3>
                    <p>We are inviting you to come onboard the Octa Investment investment platform and begin enjoying outstanding returns on your investment.</p>
                    <div className="display-inline-block vertical-align-middle font-weight-700 font-size18 sm-font-size16 margin-25px-right">Register</div>
                    <div className="display-inline-block vertical-align-middle about-video padding-20px-tb">
                        <a className="video video_btn video_btn-style2 small" href="https://dashboard.octainvtradings.com/signup"><i className="fas fa-key"></i></a>
                    </div>
                </div>

            </div>
        </div>
    </div>
</section>
{/* end begin investing section */}

</section>
{/* end highlights section */}

{/* start price section */}
<section className="price bg-light-gray">
            <div className="container">

                <div className="section-heading title-style4 border-bottom padding-25px-bottom sm-padding-15px-bottom">
                    <h3><span>Popular</span> Investment Plans</h3>
                    <p className="width-55 sm-width-75 xs-width-95">We have a plan for every investor. Below are some of our popular investment plans. We are sure you'll find one that fits your budget and goals</p>
                </div>

                <div className="row">

                    {/* start table */}
                                        <div className="col-lg-4">
                        <div className="item text-center">
                            <div className="type">
                                <span className="icon"><i className="icofont icofont-diamond"></i></span>
                                <h4>GOLD</h4>
                            </div>
                            <div className="value">
                                <h3>500<span>$</span></h3>
                                <span className="per">Minimum</span>
                            </div>
                            <div className="features">
                                <ul>
                                    <li>Starting at $500</li>
                                    <li>0% Commission</li>
                                    <li>0.57% ROI</li>
                                    <li>4 Week(s)</li>
                                    <li>Referral Bonus</li>
                                </ul>
                            </div>
                            <div className="order">
                                <a href="https://dashboard.octainvtradings.com/signup" className="butn small"><span>Choose Plan</span></a>
                            </div>
                        </div>
                    </div>
                                            <div className="col-lg-4">
                        <div className="item text-center">
                            <div className="type">
                                <span className="icon"><i className="icofont icofont-diamond"></i></span>
                                <h4>SILVER</h4>
                            </div>
                            <div className="value">
                                <h3>5,000<span>$</span></h3>
                                <span className="per">Minimum</span>
                            </div>
                            <div className="features">
                                <ul>
                                    <li>Starting at $5,000</li>
                                    <li>0% Commission</li>
                                    <li>0.85% ROI</li>
                                    <li>5 Week(s)</li>
                                    <li>Referral Bonus</li>
                                </ul>
                            </div>
                            <div className="order">
                                <a href="https://dashboard.octainvtradings.com/signup" className="butn small"><span>Choose Plan</span></a>
                            </div>
                        </div>
                    </div>
                                            <div className="col-lg-4">
                        <div className="item text-center">
                            <div className="type">
                                <span className="icon"><i className="icofont icofont-diamond"></i></span>
                                <h4>DIAMOND</h4>
                            </div>
                            <div className="value">
                                <h3>8,000<span>$</span></h3>
                                <span className="per">Minimum</span>
                            </div>
                            <div className="features">
                                <ul>
                                    <li>Starting at $8,000</li>
                                    <li>0% Commission</li>
                                    <li>1.14% ROI</li>
                                    <li>6 Week(s)</li>
                                    <li>Referral Bonus</li>
                                </ul>
                            </div>
                            <div className="order">
                                <a href="https://dashboard.octainvtradings.com/signup" className="butn small"><span>Choose Plan</span></a>
                            </div>
                        </div>
                    </div>
                                            <div className="col-lg-4">
                        <div className="item text-center">
                            <div className="type">
                                <span className="icon"><i className="icofont icofont-diamond"></i></span>
                                <h4>VIP</h4>
                            </div>
                            <div className="value">
                                <h3>25,000<span>$</span></h3>
                                <span className="per">Minimum</span>
                            </div>
                            <div className="features">
                                <ul>
                                    <li>Starting at $25,000</li>
                                    <li>0% Commission</li>
                                    <li>1.42% ROI</li>
                                    <li>7 Week(s)</li>
                                    <li>Referral Bonus</li>
                                </ul>
                            </div>
                            <div className="order">
                                <a href="https://dashboard.octainvtradings.com/signup" className="butn small"><span>Choose Plan</span></a>
                            </div>
                        </div>
                    </div>
                                            <div className="col-lg-4">
                        <div className="item text-center">
                            <div className="type">
                                <span className="icon"><i className="icofont icofont-diamond"></i></span>
                                <h4>VIP+</h4>
                            </div>
                            <div className="value">
                                <h3>100,000<span>$</span></h3>
                                <span className="per">Minimum</span>
                            </div>
                            <div className="features">
                                <ul>
                                    <li>Starting at $100,000</li>
                                    <li>0% Commission</li>
                                    <li>1.71% ROI</li>
                                    <li>6 Week(s)</li>
                                    <li>Referral Bonus</li>
                                </ul>
                            </div>
                            <div className="order">
                                <a href="https://dashboard.octainvtradings.com/signup" className="butn small"><span>Choose Plan</span></a>
                            </div>
                        </div>
                    </div>
                        
                    {/* end table */}
                </div>
            </div>
            

        </section>
        {/* end price section */}

        {/* start request form */}
        <section className="parallax" data-overlay-dark="4" data-background="./img//bg/request-call.jpg">
            <div className="container">

                <div className="section-heading title-style4 white border-bottom border-color-light-white padding-25px-bottom sm-padding-15px-bottom">
                    <h3><span>Request</span> a call</h3>
                    <p className="width-55 sm-width-75 xs-width-95">We have dedicated investment advisors who are available to give a free consultation call, and guide you on how to get started on your investment.</p>
                </div>

                <div className="row">

                    <div className="col-lg-10 center-col">
                        <div className="contact-form-box">

                            {/* start form here */}

                            <form className="quform" action="#" method="post" encType="multipart/form-data" onclick="">

                                <div className="quform-elements">

                                    <div className="row">

                                        {/* Begin Text input element */}
                                        <div className="col-md-6">
                                            <div className="quform-element form-group">
                                                <div className="quform-input">
                                                    <input id="name" type="text" name="name" placeholder="Your name here" />
                                                </div>
                                            </div>

                                        </div>
                                        {/* End Text input element */}

                                        {/* Begin Text input element */}
                                        <div className="col-md-6">
                                            <div className="quform-element form-group">
                                                <div className="quform-input">
                                                    <input id="email" type="text" name="email" placeholder="Your email here" />
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Text input element */}

                                        {/* Begin Text input element */}
                                        <div className="col-md-6">
                                            <div className="quform-element form-group">
                                                <div className="quform-input">
                                                    <input id="phone" type="text" name="phone" placeholder="Your phone number" />
                                                </div>
                                            </div>

                                        </div>
                                        {/* End Text input element */}

                                        {/* Begin Submit button */}
                                        <div className="col-md-6">
                                            <div className="quform-submit-inner">
                                                <button className="butn white-hover theme" type="submit"><span>Request Call</span></button>
                                            </div>
                                            <div className="quform-loading-wrap text-left"><span className="quform-loading"></span></div>
                                        </div>
                                        {/* End Submit button */}

                                    </div>

                                </div>

                            </form>

                            {/* end form here */}

                        </div>
                    </div>

                </div>

            </div>
        </section>
        {/* end request form */}

       



{/* end blog section */}


        {/* start clients section */}
        <div className="section-clients bg-light-light">
            <div className="container">
                <div className="owl-carousel owl-theme clients" id="clients">
                    <div className="item"><img alt="partner-image" src="/img/partners/client-01.png"></img></div>
                    <div className="item"><img alt="partner-image" src="./img//partners/client-02.png"></img></div>
                    <div className="item"><img alt="partner-image" src="./img//partners/client-03.png"></img></div>
                    <div className="item"><img alt="partner-image" src="./img//partners/client-04.png"></img></div>
                    <div className="item"><img alt="partner-image" src="./img//partners/client-05.png"></img></div>
                    <div className="item"><img alt="partner-image" src="./img//partners/client-06.png"></img></div>
                    <div className="item"><img alt="partner-image" src="./img//partners/client-07.png"></img></div>
                    <div className="item"><img alt="partner-image" src="./img//partners/client-08.png"></img></div>
                    <div className="item"><img alt="partner-image" src="./img//partners/client-09.png"></img></div>
                    <div className="item"><img alt="partner-image" src="./img//partners/client-10.png"></img></div>
                </div>
            </div>
        </div>
        {/* end clients section */}




        {/* start footer section */}
    <footer className="bg-img background-size-cover" data-overlay-dark="9" data-background="./img//bg/footer-bga.jpg">
        <div className="container">
            <div className="row">

                <div className="col-lg-4 col-md-6 sm-margin-50px-bottom xs-margin-30px-bottom">
                    <div className="padding-30px-right border-right xs-border-bottom xs-no-border-right  border-color-light-white xs-padding-30px-bottom xs-no-padding-right height-100">
                        <h3 className="footer-title-style7 text-white">Contact Us</h3>
                        <ul className="footer-list">
                            <li>
                                <span className="d-inline-block vertical-align-top font-size18"><i className="fas fa-map-marker-alt text-theme-color"></i></span>
                                <span className="d-inline-block width-85 vertical-align-top padding-10px-left">Flat 221 Langworthy Road, Salford, United Kingdom, M6 5PQ</span>
                            </li>

                            <li>
                                <span className="d-inline-block vertical-align-top font-size18"><i className="far fa-envelope text-theme-color"></i></span>
                                <span className="d-inline-block width-85 vertical-align-top padding-10px-left">support@octainvtradings.com</span>
                            </li>

                        </ul>
                        <div className="footer-social-icons small margin-15px-top">
                            <ul>

                            </ul>
                        </div>
                    </div>
                </div>

                <div className="col-lg-4 col-md-6 sm-margin-50px-bottom xs-margin-30px-bottom">
                    <div className="padding-30px-right sm-no-padding-right border-right xs-border-bottom sm-no-border-right border-color-light-white xs-padding-30px-bottom height-100">
                        <h3 className="footer-title-style7 text-white">Newsletter</h3>
                        <p className="margin-20px-bottom">You can subscribe to our free newsletter to stay updated with information anf offers from Octa Investment</p>
                        <form className=" newsletter-form w-sm-90 mx-auto mx-lg-0" action="#" >

                            <div className="quform-elements">

                                <div className="row">

                                    {/* Begin Text input element */}
                                    <div className="col-md-12">
                                        <div className="quform-element">
                                            <div className="quform-input">
                                                <input className="form-control" id="email_address" type="text" name="email_address" placeholder="Subscribe with Octa Investment" />
                                            </div>
                                        </div>
                                    </div>
                                    {/* End Text input element */}

                                    {/* Begin Submit button */}
                                    <div className="col-md-12">
                                        <div className="quform-submit-inner">
                                            <button className="btn btn-white text-theme-color m-0" type="submit"><i className="fas fa-paper-plane"></i></button>
                                        </div>
                                        <div className="quform-loading-wrap"><span className="quform-loading"></span></div>
                                    </div>
                                    {/* End Submit button */}

                                </div>

                            </div>

                        </form>
                    </div>

                </div>
                <div className="col-lg-4 col-md-6">
                    <div className="height-100">
                        <h3 className="footer-title-style7 text-white">About Us</h3>
                        <p className="margin-20px-bottom">Octa Investment is a company involved in a blockchain assets hedge fund.</p>
                        <p className="margin-20px-bottom">The company invests in cryptocurrency protocols, companies, stocks, and stock trading.</p>
                        <a href="about.html" className="butn theme white-hover small"><span>Read More</span></a>
                    </div>
                </div>
                
                <div id="google_translate_element"></div>

              
            </div>

        </div>

        <div className="footer-bar bg-transparent border-top border-color-light-white position-relative z-index-1">
            <div className="container">
                <p>&copy;
                    <script>
                        document.write(new Date().getFullYear())
                    </script> Octa Investment</p>
            </div>
        </div>
    </footer>
    {/* end footer section */}

</div>
{/* end main-wrapper section */}

{/* start scroll to top */}
<a href="#!" className="scroll-to-top"><i className="fas fa-angle-up" aria-hidden="true"></i></a>
{/* end scroll to top */}

{/* all js include start */}

{/* jquery */}
<script src="assets/miner/js/jquery.min.js"></script>

{/* popper js */}
<script src="assets/miner/js/popper.min.js"></script>

{/* bootstrap */}
<script src="assets/miner/js/bootstrap.min.js"></script>

{/* navigation */}
<script src="assets/miner/js/nav-menu.js"></script>

{/* serch */}
<script src="assets/miner/search/search.js"></script>

{/* tab */}
<script src="assets/miner/js/easy.responsive.tabs.js"></script>

{/* owl carousel */}
<script src="assets/miner/js/owl.carousel.js"></script>

{/* jquery.counterup.min */}
<script src="assets/miner/js/jquery.counterup.min.js"></script>

{/* stellar js */}
<script src="assets/miner/js/jquery.stellar.min.js"></script>

{/* waypoints js */}
<script src="assets/miner/js/waypoints.min.js"></script>

{/* countdown js */}
<script src="assets/miner/js/countdown.js"></script>

{/* jquery.magnific-popup js */}
<script src="assets/miner/js/jquery.magnific-popup.min.js"></script>

{/* isotope.pkgd.min js */}
<script src="assets/miner/js/isotope.pkgd.min.js"></script>

{/*  chart js */}
<script src="assets/miner/js/chart.min.js"></script>

{/* thumbs js */}
<script src="assets/miner/js/owl.carousel.thumbs.js"></script>

{/* animated js */}
<script src="assets/miner/js/animated-headline.js"></script>

{/*  clipboard js */}
<script src="assets/miner/js/clipboard.min.js"></script>

{/*  prism js */}
<script src="assets/miner/js/prism.js"></script>

{/* revolution slider js files start */}
<script src="assets/miner/js/rev_slider/jquery.themepunch.tools.min.js"></script>
<script src="assets/miner/js/rev_slider/jquery.themepunch.revolution.min.js"></script>
<script src="assets/miner/js/rev_slider/extensions/revolution.extension.actions.min.js"></script>
<script src="assets/miner/js/rev_slider/extensions/revolution.extension.carousel.min.js"></script>
<script src="assets/miner/js/rev_slider/extensions/revolution.extension.kenburn.min.js"></script>
<script src="assets/miner/js/rev_slider/extensions/revolution.extension.layeranimation.min.js"></script>
<script src="assets/miner/js/rev_slider/extensions/revolution.extension.migration.min.js"></script>
<script src="assets/miner/js/rev_slider/extensions/revolution.extension.navigation.min.js"></script>
<script src="assets/miner/js/rev_slider/extensions/revolution.extension.parallax.min.js"></script>
<script src="assets/miner/js/rev_slider/extensions/revolution.extension.slideanims.min.js"></script>
<script src="assets/miner/js/rev_slider/extensions/revolution.extension.video.min.js"></script>
{/* 
custom scripts */}
<script src="assets/miner/js/main.js"></script>

{/* quform plugins js */}
<script src="assets/miner/quform/js/plugins.js"></script>

{/* quform scripts js */}
<script src="assets/miner/quform/js/scripts.js"></script>

{/* all js include end */}

{/*<script src="//code.tidio.co/wr50arirncaluremifgu47qslmkzatzk.js" async></script>*/}




</body>

        </>
        

    );
}
